/**
 * The transition timings used here need to match 1:1 to the timings
 * set in `AnimationHelper.FadeTransitionTimeouts`.
 */
.lia-g-csst {
  &-fade {
    &-enter,
    &-appear {
      opacity: 0;
      pointer-events: none;

      &-active {
        opacity: 1;
        transition: opacity var(--lia-timing-normal) var(--lia-timing-function);
      }
    }
    &-exit {
      opacity: 1;
      pointer-events: none;

      &-active {
        opacity: 0;
        transition: opacity var(--lia-timing-normal) var(--lia-timing-function);
      }
    }
  }

  &-fade-slow {
    &-enter,
    &-appear {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity var(--lia-timing-slow) var(--lia-timing-function);
      }
    }
    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity var(--lia-timing-slow) var(--lia-timing-function);
      }
    }
  }

  /* AnimationHelper.ZoomFadeTransitionTimeouts */
  &-zoom-fade {
    &-enter,
    &-appear {
      transform: scale(0.9);
      opacity: 0;

      &-active {
        opacity: 1;
        transform: scale(1);
        transition: all var(--lia-timing-normal) var(--lia-timing-function);
        transition-property: opacity, transform;
      }
    }
    &-exit {
      transform: scale(1);
      opacity: 1;

      &-active {
        transform: scale(0.9);
        opacity: 0;
        transition: all var(--lia-timing-normal) var(--lia-timing-function);
        transition-property: opacity, transform;
      }
    }
  }

  &-move-left {
    &-enter,
    &-appear {
      transform: translateX(100vw);

      &-active {
        transform: translateX(0);
        transition: transform var(--lia-timing-normal);
      }
    }
    &-exit {
      transform: translateX(0);

      &-active {
        transform: translateX(100vw);
        transition: transform var(--lia-timing-normal);
      }
    }
  }

  &-move-right {
    &-enter,
    &-appear {
      transform: translateX(-100vw);

      &-active {
        transform: translateX(0);
        transition: transform var(--lia-timing-normal);
      }
    }
    &-exit {
      transform: translateX(0);

      &-active {
        transform: translateX(-100vw);
        transition: transform var(--lia-timing-normal);
      }
    }
  }

  &-slide-in {
    &-enter {
      opacity: 0;
      transform: scale(0.9) translateX(100px);

      &-active {
        opacity: 1;
        transform: scale(1) translateX(0);
        transition: all var(--lia-timing-normal) cubic-bezier(0.24, 0.94, 0.62, 1);
        transition-property: opacity, transform;
      }
    }
    &-exit {
      opacity: 1;

      &-active {
        transition: opacity var(--lia-timing-normal) cubic-bezier(0.24, 0.94, 0.62, 1);
        opacity: 0;
      }
    }
  }

  &-slide-up {
    &-enter {
      opacity: 0;
      transform: scale(0.9) translateY(35px);

      &-active {
        opacity: 1;
        transform: scale(1) translateY(0);
        transition: all var(--lia-timing-slow) cubic-bezier(0.26, 1.44, 0.45, 0.84);
        transition-property: opacity, transform;
      }
    }
    &-exit {
      opacity: 1;

      &-active {
        transition: opacity var(--lia-timing-slow) cubic-bezier(0.24, 0.94, 0.62, 1);
        opacity: 0;
      }
      &-done {
        opacity: 0;
      }
    }
  }
  &-squish {
    &-enter {
      transform: rotate(0deg) scale(1);

      &-active {
        transform: rotate(25deg) scale(0.7);
        transition: transform var(--lia-timing-slow) var(--lia-timing-function);
      }
    }
    &-exit {
      transform: rotate(0deg) scale(1.3);

      &-done {
        transform: rotate(0deg) scale(1);
      }
    }
  }
}

.lia-g-will-load {
  transition: opacity var(--lia-timing-normal) var(--lia-timing-function);

  &.lia-g-is-loaded {
    opacity: 1;
  }
  &.lia-g-is-reloading {
    opacity: 0.5;
  }
}
