@import '../mixins/_spoiler.pcss';
@import '../mixins/_highlight.pcss';

.lia-g-message-body {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--lia-rte-default-message-header-mt);
    margin-bottom: var(--lia-rte-default-message-header-mb);
  }

  a {
    word-break: break-word;
  }

  p,
  pre {
    margin-top: var(--lia-rte-default-message-item-mt);
    margin-bottom: var(--lia-rte-default-message-item-mb);
  }

  pre {
    clear: both;
    white-space: pre-wrap;
  }

  img {
    max-width: 100%;
    border-radius: var(--lia-bs-border-radius-sm);
  }

  figcaption:empty {
    display: none;
  }

  table {
    overflow-wrap: normal;
    word-wrap: normal;
    word-break: normal;
  }

  blockquote {
    padding: 20px 20px 20px 30px;
    background-color: var(--lia-bs-gray-200);
    border-radius: var(--lia-bs-border-radius-sm);
    border-left: 5px solid var(--lia-bs-primary);
    margin-top: var(--lia-rte-default-message-item-mt);
    margin-bottom: var(--lia-rte-default-message-item-mb);
    clear: both;

    > p:last-of-type,
    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }

    .lia-blockquote-author-title {
      font-weight: var(--lia-bs-font-weight-bold);
      font-size: var(--lia-bs-font-size-sm);
    }

    .lia-blockquote-author-text {
      font-size: var(--lia-bs-font-size-sm);
    }
  }

  .lia-table-wrapper {
    margin-top: var(--lia-rte-default-message-item-mt);
    margin-bottom: var(--lia-rte-default-message-item-mb);
  }

  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;
  }

  :global {
    .mce-content-body {
      > *:first-child {
        margin-top: 0 !important;
      }
      > *:last-child {
        margin-bottom: 0 !important;
      }

      .lia-anchor:has(+ .lia-linked-item:hover),
      .lia-anchor:has(+ .lia-linked-item:focus) {
        opacity: 0.5;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }

      .lia-linked-item {
        position: relative;

        &:hover .lia-anchor,
        &:focus .lia-anchor {
          opacity: 0.5;

          &:hover,
          &:focus {
            opacity: 1;
          }
        }
      }

      .lia-anchor {
        visibility: visible;
        position: relative;
        transition: opacity var(--lia-timing-normal) var(--lia-timing-function);
        z-index: 2;

        &:after,
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          min-height: 22px;
          min-width: 22px;
          transform: translate(-10px, -11px);
        }

        &:after {
          border-radius: var(--lia-bs-border-radius-sm);
          box-shadow: var(--lia-box-shadow-xs);
          background-color: var(--lia-bs-white);
        }

        &:before {
          mask-image: var(--lia-anchor-icon);
          mask-repeat: no-repeat;
          mask-position: center;
          background-color: var(--lia-bs-gray-700);
          z-index: 2;
        }

        &[data-mce-selected]:after {
          box-shadow: 0 0 0 2px var(--lia-outline-color);
        }

        &[contenteditable='false'][data-mce-selected] {
          outline: none;
        }
      }
    }

    .lia-anchor {
      visibility: hidden;
    }

    .lia-media-object {
      > a {
        display: inline-block;
      }

      & img {
        pointer-events: none;
        width: 100%;
        height: auto;
      }
    }

    .lia-media-unstyled-btn {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      width: 100%;
    }

    .lia-image-align-inline {
      width: auto !important;
    }

    .lia-media-size-small {
      @media (--lia-breakpoint-up-sm) {
        max-width: 80%;
      }
      @media (--lia-breakpoint-up-md) {
        max-width: 60%;
      }
      @media (--lia-breakpoint-up-lg) {
        max-width: 40%;
      }
      @media (--lia-breakpoint-up-xl) {
        max-width: 30%;
      }
    }

    .lia-media-size-medium {
      @media (--lia-breakpoint-up-sm) {
        max-width: 90%;
      }
      @media (--lia-breakpoint-up-md) {
        max-width: 80%;
      }
      @media (--lia-breakpoint-up-lg) {
        max-width: 70%;
      }
      @media (--lia-breakpoint-up-xl) {
        max-width: 60%;
      }
    }

    .lia-media-is-left {
      display: table;
      margin: 5px 30px 20px 0;
      float: left;

      @media (--lia-breakpoint-down-sm) {
        margin-left: auto;
        margin-right: auto;
        float: none;
      }
    }

    .lia-media-is-right {
      display: table;
      margin: 5px 0 20px 30px;
      float: right;

      @media (--lia-breakpoint-down-sm) {
        margin-left: auto;
        margin-right: auto;
        float: none;
      }
    }

    .lia-media-is-center {
      display: table;
      margin: 5px auto 20px;
      float: none;
      clear: both;
    }

    .lia-media-is-no-align {
      display: table;
      margin: 5px 0 20px;
      clear: both;
    }

    .lia-media-size-large {
      width: 100%;
      min-width: 100%;
      margin: 5px 0 20px;
      clear: both;
    }

    .lia-video-container {
      position: relative;
      display: flex;
      flex-direction: column;
      aspect-ratio: 16/9;
      width: 100%;
    }

    .lia-video-player {
      background-color: var(--lia-bs-gray-300);
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
    }

    .lia-video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .lia-video-display-wrapper {
      background-color: var(--lia-bs-gray-200);
    }

    .lia-video-processing-indicator {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: var(--lia-bs-gray-300);
      opacity: 0.8;
      color: var(--lia-bs-gray-900);
      font-size: var(--lia-bs-font-size-sm);
      height: 40px;
    }

    .lia-media-size-small {
      .lia-video-processing-indicator {
        font-size: var(--lia-font-size-xs);
        height: 30px;
      }

      .lia-video-player-icon {
        margin-top: 15px;
      }

      .lia-video-player-icon,
      .vjs-big-play-button {
        width: 50px;
        height: 50px;
      }

      .vjs-big-play-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        transform: translate(-50%, -50%);

        .vjs-icon-placeholder {
          display: none;
        }

        &:after {
          content: '';
          mask-image: var(--lia-video-play-active-icon);
          background-color: var(--lia-bs-white);
          width: 30px;
          height: 30px;
          mask-size: 30px;
        }
      }

      .vjs-error .vjs-big-play-button,
      .vjs-has-started .vjs-big-play-button {
        display: none;
      }
    }

    .lia-video-upload-finalise-indicator {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .lia-video-player-icon {
      background-color: var(--lia-bs-gray-300);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      opacity: 0.8;
      top: 50%;
      left: 50%;
      margin-top: 20px;
      width: 100px;
      height: 100px;
      transform: translate(-50%, -50%);
      border-radius: 50%;

      &:after {
        content: '';
        mask-image: var(--lia-video-play-disabled-icon);
        background-color: var(--lia-bs-gray-600);
        width: 30px;
        height: 30px;
        mask-size: 30px;
      }
    }

    .lia-media-caption-text {
      color: var(--lia-bs-gray-700);
      font-size: var(--lia-font-size-xs);
      padding: 10px 0;
      max-width: min-content;
      min-width: 100%;
      width: -moz-available;
      width: -webkit-fill-available;
      display: block;
    }

    .lia-iframe-embeded {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
      border-radius: var(--lia-bs-border-radius-sm);
    }

    .lia-embeded-content {
      width: 100%;
      clear: both;
      margin-bottom: var(--lia-rte-default-message-item-mb);

      .lia-anchor {
        &::after,
        &::before {
          display: none;
        }
      }

      .mce-item-table td {
        border: none;
      }
    }

    /* Media query for Firefox */
    @supports (-moz-appearance: none) {
      .lia-media-caption-text {
        width: -moz-available;
      }
    }

    .lia-internal-url,
    .lia-mention {
      &:before {
        content: '';
        display: inline-block;
        mask-repeat: no-repeat;
        mask-size: 12px;
        margin-right: 4px;
        width: 12px;
        height: 12px;
      }
    }

    .lia-internal-url {
      &-user:before {
        mask-image: var(--lia-user-icon);
        background-color: var(--lia-bs-primary);
      }
      &-content-type {
        &-forum:before {
          mask-image: var(--lia-content-discussion-icon);
          background-color: var(--lia-forum-color);
        }
        &-blog:before {
          mask-image: var(--lia-content-blog-icon);
          background-color: var(--lia-blog-color);
        }
        &-tkb:before {
          mask-image: var(--lia-content-knowledge-icon);
          background-color: var(--lia-tkb-color);
        }
        &-contest:before {
          mask-image: var(--lia-content-contest-icon);
          background-color: var(--lia-contest-color);
        }
        &-qanda:before {
          mask-image: var(--lia-content-discussion-question-icon);
          background-color: var(--lia-qanda-color);
        }
        &-occasion:before {
          mask-image: var(--lia-content-occasion-icon);
          background-color: var(--lia-occasion-color);
        }
        &-idea:before {
          mask-image: var(--lia-content-idea-icon);
          background-color: var(--lia-idea-color);
        }
        &-grouphub:before {
          mask-image: var(--lia-content-group-icon);
          background-color: var(--lia-grouphub-color);
        }
        &-category:before {
          mask-image: var(--lia-content-category-icon);
          background-color: var(--lia-category-color);
        }
      }
    }

    .lia-mention {
      &-user:before {
        mask-image: var(--lia-user-mention-icon);
        background-color: var(--lia-bs-primary);
      }
      &-node-type {
        &-grouphub:before {
          mask-image: var(--lia-content-group-icon);
          background-color: var(--lia-grouphub-color);
        }
        &-category:before {
          mask-image: var(--lia-content-category-icon);
          background-color: var(--lia-category-color);
        }
      }
      &-content-type {
        &-FORUM:before {
          mask-image: var(--lia-content-discussion-icon);
          background-color: var(--lia-forum-color);
        }
        &-BLOG:before {
          mask-image: var(--lia-content-blog-icon);
          background-color: var(--lia-blog-color);
        }
        &-TKB:before {
          mask-image: var(--lia-content-knowledge-icon);
          background-color: var(--lia-tkb-color);
        }
        &-CONTEST:before {
          mask-image: var(--lia-content-contest-icon);
          background-color: var(--lia-contest-color);
        }
        &-QANDA:before {
          mask-image: var(--lia-content-discussion-question-icon);
          background-color: var(--lia-qanda-color);
        }
        &-OCCASION:before {
          mask-image: var(--lia-content-occasion-icon);
          background-color: var(--lia-occasion-color);
        }
        &-IDEA:before {
          mask-image: var(--lia-content-idea-icon);
          background-color: var(--lia-idea-color);
        }
      }
    }

    .lia-external-url:after {
      display: inline-block;
      content: '';
      mask-size: 12px;
      width: 12px;
      height: 12px;
      top: 3px;
      margin-left: 4px;
      mask-image: var(--lia-external-link-icon);
      background-color: var(--lia-bs-primary);
      user-select: none;
    }

    .lia-video-container {
      img {
        aspect-ratio: 16/9;
        width: 100%;
      }
      & > iframe {
        border-radius: var(--lia-bs-border-radius-sm);
        border: none;
        height: 100%;
      }
    }

    .video-js {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;

      .vjs-big-play-button {
        background-color: var(--lia-bs-primary);
        opacity: 0.8;
      }
      .vjs-play-progress.vjs-slider-bar,
      .vjs-volume-panel .vjs-volume-level {
        background-color: var(--lia-bs-primary);
      }

      .vjs-dock-text {
        display: none;
      }

      .vjs-error-display {
        &:before {
          content: none;
        }
      }

      .vjs-errors-dialog {
        border: none;
        background: none;
        position: unset;

        .vjs-errors-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }

        .vjs-errors-headline {
          margin: 0;
          color: var(--lia-bs-gray-300);
        }

        .vjs-errors-code {
          display: none;
        }

        .vjs-errors-ref-id {
          display: none;
        }
      }
    }
  }

  &-blog,
  &-tkb {
    blockquote {
      margin-top: var(--lia-rte-special-message-item-mt);
      margin-bottom: var(--lia-rte-special-message-item-mb);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: var(--lia-rte-special-message-header-mt);
      margin-bottom: var(--lia-rte-special-message-header-mb);
    }

    p,
    pre {
      margin-top: var(--lia-rte-special-message-item-mt);
      margin-bottom: var(--lia-rte-special-message-item-mb);
    }

    *:last-child {
      margin-bottom: 0;
    }

    :global {
      .lia-code-sample {
        margin-top: var(--lia-rte-special-message-item-mt);
        margin-bottom: var(--lia-rte-special-message-item-mb);
      }

      .mce-toc {
        margin-bottom: var(--lia-rte-special-message-header-mb);
      }
      .lia-spoiler-container {
        @include spoiler-container-margin-styles(
          --lia-rte-special-message-item-mt,
          --lia-rte-special-message-item-mb
        );
      }
    }

    .lia-g-spoiler-editor {
      @include spoiler-container-margin-styles(
        --lia-rte-special-message-item-mt,
        --lia-rte-special-message-item-mb
      );
    }
  }

  @each $type in (forum, blog, tkb, qanda, idea, content, occasion) {
    &-$type {
      font-family: var(--lia-#{$type}-font-family);

      p {
        font-weight: var(--lia-#{$type}-font-weight);
        font-style: var(--lia-#{$type}-font-style);
        line-height: var(--lia-#{$type}-line-height);
      }
      a {
        color: var(--lia-#{$type}-message-link-color);
        font-style: var(--lia-#{$type}-message-link-font-style);
        font-weight: var(--lia-#{$type}-message-link-font-weight);
        text-decoration: var(--lia-#{$type}-message-link-decoration);

        &:hover {
          color: var(--lia-#{$type}-message-link-color);
          text-decoration: var(--lia-#{$type}-message-link-decoration);
        }
      }
    }
  }

  :global {
    .mce-toc {
      margin-bottom: var(--lia-rte-default-message-header-mb);

      h2 {
        color: var(--lia-bs-body-color);
        font-weight: var(--lia-bs-font-weight-bold);
        font-size: var(--lia-bs-font-size-sm);
        border-bottom: 1px solid var(--lia-bs-border-color);
        padding-bottom: 5px;
        margin: 0;
      }

      ul {
        padding-left: 15px;
      }

      & > ul {
        padding-left: 0;
      }

      li {
        list-style: none;
        margin-top: 5px;

        a:not(:empty):before {
          content: '-';
          margin-right: 10px;
          color: var(--lia-bs-gray-600);
          text-decoration: none;
          display: inline-block;
        }
      }
    }

    .lia-align {
      &-right {
        text-align: right;
      }
      &-left {
        text-align: left;
      }
      &-center {
        text-align: center;
      }
      &-justify {
        text-align: justify;
      }
    }

    img.lia-item-align {
      &-right {
        float: right;
      }
      &-left {
        float: left;
      }
      &-center {
        display: block;
        margin: 0 auto;
      }
    }

    .lia-search-match-lithium {
      @include default-highlight();
    }

    @for $i from 0 to $lia-legacy-max-indentation by $lia-indentation-step {
      @if $i <= $lia-max-indentation {
        .lia-indent-padding-left-$(i)px {
          /* prettier-ignore */
          padding-left: $(i)px;
        }

        .lia-indent-padding-right-$(i)px {
          /* prettier-ignore */
          padding-right: $(i)px;
        }

        .lia-indent-margin-left-$(i)px {
          /* prettier-ignore */
          margin-left: $(i)px;
        }

        .lia-indent-margin-right-$(i)px {
          /* prettier-ignore */
          margin-right: $(i)px;
        }
      } @else {
        .lia-indent-padding-left-$(i)px {
          /* prettier-ignore */
          padding-left: $(lia-max-indentation)px;
        }

        .lia-indent-padding-right-$(i)px {
          /* prettier-ignore */
          padding-right: $(lia-max-indentation)px;
        }

        .lia-indent-margin-left-$(i)px {
          /* prettier-ignore */
          margin-left: $(lia-max-indentation)px;
        }

        .lia-indent-margin-right-$(i)px {
          /* prettier-ignore */
          margin-right: $(lia-max-indentation)px;
        }
      }
    }

    .lia-spoiler-container {
      @include spoiler-container-margin-styles;
      @include spoiler-container-common-styles;
      padding: 20px;
      clear: both;

      .lia-spoiler-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--lia-bs-body-color);
        padding: 20px;
        margin: -20px;

        &:hover,
        &:focus {
          color: var(--lia-bs-body-color);
          text-decoration: none;
        }
        &:after {
          display: block;
          content: '';
          width: 16px;
          height: 16px;
          background-size: cover;
          background-color: var(--lia-bs-gray-900);
          mask-size: 16px;
          mask-image: var(--lia-chevron-down-icon);
          transform: rotate(-90deg);
          transition: transform var(--lia-timing-normal);
          padding-left: 16px;
        }
      }
      .lia-spoiler-border {
        display: none;
        border-top: 1px solid var(--lia-bs-border-color);
        margin-top: 20px;
        padding-top: 20px;
      }
      .lia-spoiler-content {
        &:after {
          content: '';
          display: table;
          clear: both;
        }
        > *:last-child {
          margin-bottom: 0 !important;
        }
      }
      &.lia-spoiler-show {
        .lia-spoiler-border {
          display: block;
        }
        .lia-spoiler-link:after {
          transform: rotate(0deg);
        }
      }
    }

    .lia-rich-preview-card {
      clear: both;
    }

    .lia-rich-preview-card-container {
      display: flex;
      flex-direction: row;
      border: 1px solid var(--lia-bs-border-color);
      border-radius: 5px;
      clear: both;
      @media (--lia-breakpoint-down-sm) {
        flex-direction: column;
      }
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    .lia-rich-preview-card-content {
      display: block;
      padding: 20px;
    }

    .lia-rich-preview-card-header {
      display: flex;
      align-items: center;
      column-gap: 5px;
      margin-bottom: 10px;

      &:after {
        display: block;
        flex-shrink: 0;
        content: '';
        mask-size: 12px;
        width: 12px;
        height: 12px;
        mask-image: var(--lia-external-link-icon);
        background-color: var(--lia-bs-gray-900);
        user-select: none;
      }

      h5 {
        margin: 0;
        color: var(--lia-bs-gray-900);
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }

    .lia-rich-preview-card-image {
      width: 156px;
      height: 156px;
      border-radius: 0;
      object-fit: cover;
      @media (--lia-breakpoint-down-sm) {
        width: 100%;
      }
    }

    .lia-rich-preview-card-description {
      line-height: 1.4;
      color: var(--lia-bs-gray-900);
      margin-bottom: 10px;
      display: -webkit-box !important;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }

    .lia-rich-preview-card-provider-name {
      font-size: var(--lia-bs-font-size-sm);
      color: var(--lia-bs-gray-700);

      img {
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
    }

    .lia-clear-both {
      clear: both;
    }

    @for $i from 1 to 22 {
      .lia-text-color-$i,
      .lia-text-color-$i a {
        color: var(--lia-rte-custom-color-$(i));
        :after {
          background-color: var(--lia-rte-custom-color-$(i));
        }
      }
    }
  }
}

iframe.lia-iframe-with-script {
  display: none;
}
