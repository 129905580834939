@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-g-unstyled-btn {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
}

.lia-g-action-btn {
  display: flex;
  padding: 4px 3px;
  line-height: 1.2;
  font-size: var(--lia-bs-font-size-sm);
  transition: background-color var(--lia-timing-normal);

  > span {
    color: var(--lia-bs-gray-700);
    font-weight: var(--lia-font-weight-md);
    transition: color var(--lia-timing-normal);
  }
  > svg {
    transition: fill var(--lia-timing-normal);
    fill: var(--lia-bs-gray-600);
  }
  &:hover {
    background-color: var(--lia-bs-gray-200);
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:disabled {
    opacity: 1;

    > span {
      color: var(--lia-bs-gray-600);
    }
    > svg {
      fill: var(--lia-bs-gray-500);
    }
  }
}

.lia-g-icon-btn {
  display: flex;
  padding: 4px;
  background-color: transparent;
  border: none;
  border-radius: var(--lia-border-radius-50);

  &.dropdown-toggle:after {
    display: none;
  }
  &:hover {
    background-color: hsla(
      var(--lia-bs-black-h),
      var(--lia-bs-black-s),
      var(--lia-bs-black-l),
      0.04
    );
    box-shadow: none;
  }
  @include default-focus-box-shadow();
  &:active {
    background-color: hsla(
      var(--lia-bs-black-h),
      var(--lia-bs-black-s),
      var(--lia-bs-black-l),
      0.08
    );
  }
  &:disabled {
    opacity: var(--lia-bs-btn-disabled-opacity);
  }
  &.lia-g-in-modal-header {
    padding: 8px;
    margin: 0 10px 0 auto;
  }
}

.lia-g-btn-has-loading-animation:disabled {
  opacity: 1;
}

.lia-g-link-btn {
  appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  width: auto;
  overflow: visible;
  color: var(--lia-bs-link-color);
  text-decoration: none;
  font-family: var(--lia-bs-font-family-base);
  line-height: var(--lia-bs-line-height-base);
  font-weight: var(--lia-bs-font-weight-base);

  &:hover {
    color: var(--lia-bs-link-hover-color);
    text-decoration: var(--lia-bs-link-hover-decoration);
  }
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  @include default-focus-outline();
}

.lia-g-text-btn,
.lia-g-text-btn-wrap > button {
  line-height: 1.2;
  color: var(--lia-bs-body-color);
  padding: 0;
  border: 0;
}

.lia-g-loader-btn {
  color: var(--lia-load-text-color);
  font-weight: var(--lia-load-text-font-weight);
  font-size: var(--lia-load-text-font-size);
  padding: 0;
  border: 0;

  &:hover,
  &:focus {
    color: var(--lia-load-text-color);
    text-decoration: none;
  }
}

.lia-g-editor-btn {
  background-color: var(--lia-bs-white);
  border-color: var(--lia-bs-border-color);
  line-height: 0;
  padding: 9px;

  &:hover,
  &:focus {
    background-color: var(--lia-bs-light);
    box-shadow: none;
  }
}
